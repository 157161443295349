import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SQLServerIcon from "MasterImages/tech_SQLServer.svg";
import AzureIcon from "MasterImages/tech_Azure.svg";
import ADFIcon from "MasterImages/tech_ADF.svg";
import SynapseIcon from "MasterImages/tech_Synapse.svg";
import AADIcon from "MasterImages/tech_AAD.svg";
import AzureDBMigrationIcon from "MasterImages/tech_AzureDBMigration.svg";
import AzureDevopsIcon from "MasterImages/tech_AzureDevops.svg";
import AzureSQLIcon from "MasterImages/tech_AzureSQL.svg";
import TableauIcon from "MasterImages/tech_tableau.svg";
import PowerBIIcon from "MasterImages/tech_PowerBI.svg";
import VSIcon from "MasterImages/tech_VS.svg";
import Office365Icon from "MasterImages/tech_Office365.svg";
import DotNetIcon from "MasterImages/tech_dotnet.svg";
import ReactIcon from "MasterImages/tech_react.svg";
import GitIcon from "MasterImages/tech_git.svg";
import JenkinsIcon from "MasterImages/tech_jenkins.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
 ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-8 md:py-8`}
`;

const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/3 lg:w-1/4 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8 justify-center`}
  .imageContainer {
    ${tw` text-center  p-5 flex-shrink-0`}
    img {
      ${tw`w-24 h-20`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, 
  heading = (
    <>
      We Deal With Cutting Edge <span tw="text-primary-500">Technologies</span>
    </>
  )

}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */
  const defaultCards = [
    {imageSrc: SQLServerIcon},
    { imageSrc: AzureIcon},
    { imageSrc: ADFIcon},
    { imageSrc: SynapseIcon},
    { imageSrc: AADIcon},
    { imageSrc: AzureDBMigrationIcon},
    { imageSrc: AzureSQLIcon},
    { imageSrc: TableauIcon},
    { imageSrc: PowerBIIcon},
    { imageSrc: Office365Icon},
    { imageSrc: VSIcon},
    { imageSrc: DotNetIcon},
    { imageSrc: ReactIcon},
    { imageSrc: GitIcon},
    { imageSrc: AzureDevopsIcon},
    { imageSrc: JenkinsIcon}
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{heading}</Heading>
        {/* {description && <Description>{description}</Description>} */}
        {/* <VerticalSpacer /> */}
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              {/* <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span> */}
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
