import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import apiKey from "../../helpers/EmailKey.js";
import emailjs from '@emailjs/browser';
import validator from 'validator'
// import { Linking } from 'react-native'

// import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as FacebookIcon } from "MasterImages/contact_facebookicon.svg";
import { ReactComponent as TwitterIcon } from "MasterImages/contact_twittericon.svg";
import { ReactComponent as YoutubeIcon } from "MasterImages/contact_youtubeicon.svg";
import { ReactComponent as LinkedInIcon } from "MasterImages/contact_linkedinicon.svg"
import { ReactComponent as MailIcon } from "MasterImages/contact_mailicon.svg";

// const apiKey = emailjs;

const VerticalSpacer = tw.div`mt-3 w-full`
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-8 lg:py-8`;

// const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-3 md:py-3 items-center`;

const FormContainer = styled.div`
  ${tw`p-0 sm:p-0 md:p-0 text-gray-800 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }  
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-gray-100 text-gray-500 text-base font-medium tracking-wide border-b-2 py-2 text-gray-800 hocus:border-primary-500 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between `;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-500 hocus:-translate-y-px hocus:shadow-xl`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
const SocialLinksContainer = tw.div`items-center md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-6 h-6`}
  }
`;



export default () => {

  function sendEmail(e){
    e.preventDefault(); // Prevents default refresh by the browser 

    var fromname = e.target.from_name.value;
    var email = e.target.email.value;
    var message = e.target.message.value;


    if (validator.isEmpty(fromname)) {      
      alert("Please enter valid name!");  
      return;
    }
    
    if (!validator.isEmail(email)) {      
      alert("Please enter valid email!");  
      return;
    }

    if (validator.isEmpty(message)) {      
      alert("Please enter valid message!");  
      return;
    }
    
    emailjs.sendForm(apiKey.SERVICE_ID, apiKey.TEMPLATE_ID_ENQUIRY, e.target, apiKey.PUBLIC_KEY)
    .then((result) => {
    alert("We have received your query and will get back to you shortly. Have a great day!", result.text);
    window.location.reload();
    console.log(result.text);
    },
    (error) => {
    alert("We are currently experiencing some system error and trying to resolve. Please try after sometime.", error.text);
    console.log(error.text);
    });
    };  
  
  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-full">
            <Heading><span tw="text-primary-500 text-3xl sm:text-4xl lg:text-5xl tracking-wide font-bold">Contact Us</span></Heading>
            <form onSubmit={sendEmail}>
              <TwoColumn>
                <Column tw="md:w-2/3 lg:w-2/3">
                  <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input id="name-input" type="text" name="from_name" placeholder="E.g. John Doe" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input id="email-input" type="text" name="email" placeholder="E.g. john@mail.com" />
                  </InputContainer>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Message</Label>
                    <TextArea id="message-input" name="message" placeholder="E.g. Details about your event"/>
                  </InputContainer>

                  <SubmitButton type="submit" value="Submit" >Submit</SubmitButton>
                </Column>
                <Column tw="pl-24 text-left">               

                  {/* <InputContainer >
                    <Label htmlFor="name-input" tw="text-primary-500 text-sm sm:text-base lg:text-lg">Please feel free to send us an email using the form or contact on below email.</Label>                    
                  </InputContainer> */}

                
                  <InputContainer  >
                  <Label htmlFor="name-input" tw="text-primary-500 text-sm sm:text-base lg:text-lg">Please feel free to send us an email using the form or contact on below email.</Label>

                  <VerticalSpacer/>

                  <SocialLinksContainer>
                  <SocialLink >                    
                    <MailIcon />
                  </SocialLink>
                  <Label htmlFor="name-input" >contact@emcloudtech.com</Label>                    
                  </SocialLinksContainer>

                  {/* <VerticalSpacer/>

                  <SocialLinksContainer>
                  <SocialLink href="https://linkedin.com">
                    <LinkedInIcon />
                  </SocialLink>
                  <Label htmlFor="name-input" >LinkedIn</Label>                    
                  </SocialLinksContainer>

                  <VerticalSpacer/>

                  <SocialLinksContainer>
                  <SocialLink href="https://facebook.com">
                    <FacebookIcon />
                  </SocialLink>
                  <Label htmlFor="name-input" >Facebook</Label>                    
                  </SocialLinksContainer>

                  <VerticalSpacer/>

                  <SocialLinksContainer>
                  <SocialLink href="https://twitter.com">
                    <TwitterIcon />
                  </SocialLink>
                  <Label htmlFor="name-input" >Twitter</Label>                    
                  </SocialLinksContainer>

                  <VerticalSpacer/>

                  <SocialLinksContainer>
                  <SocialLink href="https://youtube.com">
                    <YoutubeIcon/>
                  </SocialLink>
                  <Label htmlFor="name-input" >Youtube</Label>                    
                  </SocialLinksContainer> */}

                  </InputContainer>                 
                  
                </Column>
              </TwoColumn>

              
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
