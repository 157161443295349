import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/ChildComponents/HomeComponent.js";
import Services from "components/ChildComponents/ServicesComponent.js";
import Technology from "components/ChildComponents/TechnologyComponent.js";
import Aboutus from "components/ChildComponents/AboutUsComponent.js";
import ContactUs from "components/ChildComponents/ContactUsComponent.js";
import Footer from "components/ChildComponents/FooterComponent.js";

// import Header from "components/ChildComponents/CustomHeader.js";
import Header from "components/ChildComponents/light.js";

// import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const VerticalSpacer = tw.div`mt-40 w-full`

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 pt-3 overflow-hidden`;

  return (
    <>
    <Header  />
    {/* <VerticalSpacer/> */}
    {/* <AnimationRevealPage> */}
    <StyledDiv className="App">
      <div id = "home">
        
      <Hero roundedHeaderButton={true} />
      </div>
      <div id = "services">
      
      <Services id = "services" />
      </div>
      <div id = "tech">
      <Technology/>
      </div>
      <div id = "aboutus">
      <Aboutus/> 
      </div>
      <div id = "contactus">
      <ContactUs/>     
      </div>
      <Footer />
      </StyledDiv>
    {/* </AnimationRevealPage> */}

    </>
  );
}
